/**
 * @generated SignedSource<<03f013f6fdf1c683d87002dbe6e324a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobTitleIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "normIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualParagraphIds",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "jobTitleIds",
    "variableName": "jobTitleIds"
  },
  {
    "kind": "Variable",
    "name": "normIds",
    "variableName": "normIds"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v7 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v3/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditScopeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuditScopeFilters_normsInterface"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuditScopeFilters_query"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "AuditScopeManualChapters_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditScopeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Norm",
                "kind": "LinkedField",
                "name": "norms",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "type": "NormsInterface",
            "abstractKey": "__isNormsInterface"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitle",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ManualChapter",
        "kind": "LinkedField",
        "name": "manualChapters",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availability",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ciaExplanation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "confidentiality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "criticality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InformationSource",
                "kind": "LinkedField",
                "name": "informationSources",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "jobTitle",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Resource",
                "kind": "LinkedField",
                "name": "resources",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NormParagraph",
                "kind": "LinkedField",
                "name": "normParagraphs",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCia",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasQt",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "84db1091c2d5d324d95d45c6938f4b42",
    "id": null,
    "metadata": {},
    "name": "AuditScopeQuery",
    "operationKind": "query",
    "text": "query AuditScopeQuery(\n  $id: ID!\n  $jobTitleIds: [Int!]\n  $normIds: [Int!]\n  $query: String\n) {\n  audit(id: $id) {\n    databaseId\n    id\n    name\n    manualParagraphIds\n    ...AuditScopeFilters_normsInterface\n  }\n  ...AuditScopeFilters_query\n  ...AuditScopeManualChapters_query_tvHij\n}\n\nfragment AuditManualParagraphModal_manualParagraph on ManualParagraph {\n  availability\n  ciaExplanation\n  confidentiality\n  criticality\n  integrity\n  name\n  number\n  informationSources {\n    name\n    id\n  }\n  jobTitle {\n    name\n    id\n  }\n  resources {\n    name\n    id\n  }\n  normParagraphs {\n    name\n    number\n    id\n  }\n  organisation {\n    hasCia\n    hasQt\n    id\n  }\n  user {\n    name\n    id\n  }\n}\n\nfragment AuditScopeFilters_normsInterface on NormsInterface {\n  __isNormsInterface: __typename\n  norms {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment AuditScopeFilters_query on Query {\n  currentOrganisation {\n    jobTitles {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment AuditScopeManualChapterCheckbox_manualChapter on ManualChapter {\n  databaseId\n  manualParagraphIds\n  number\n}\n\nfragment AuditScopeManualChapter_manualChapter_tvHij on ManualChapter {\n  name\n  number\n  manualParagraphs(jobTitleIds: $jobTitleIds, normIds: $normIds, query: $query) {\n    databaseId\n    number\n    ...AuditScopeManualParagraph_manualParagraph\n    id\n  }\n  ...AuditScopeManualChapterCheckbox_manualChapter\n}\n\nfragment AuditScopeManualChapters_query_tvHij on Query {\n  manualChapters {\n    databaseId\n    ...AuditScopeManualChapter_manualChapter_tvHij\n    id\n  }\n}\n\nfragment AuditScopeManualParagraphCheckbox_manualParagraph on ManualParagraph {\n  databaseId\n  number\n}\n\nfragment AuditScopeManualParagraph_manualParagraph on ManualParagraph {\n  databaseId\n  name\n  number\n  ...AuditManualParagraphModal_manualParagraph\n  ...AuditScopeManualParagraphCheckbox_manualParagraph\n}\n"
  }
};
})();

node.hash = "44e681a90119981735a557a3f4637d4e";

module.exports = node;
