/**
 * @generated SignedSource<<51b1e23c304c9d31479f219ab6bf6459>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "databaseId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewAuditQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuditForm_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewAuditQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuditProgramme",
        "kind": "LinkedField",
        "name": "auditProgrammes",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NormOrganisation",
        "kind": "LinkedField",
        "name": "normOrganisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norm",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70557f1c54d5d1baf4f789e4624fd371",
    "id": null,
    "metadata": {},
    "name": "NewAuditQuery",
    "operationKind": "query",
    "text": "query NewAuditQuery {\n  ...AuditForm_query\n}\n\nfragment AuditForm_query on Query {\n  auditProgrammes {\n    databaseId\n    name\n    id\n  }\n  normOrganisations {\n    norm {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "b134ffec7395f6e93130b5420234c83b";

module.exports = node;
