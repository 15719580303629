/**
 * @generated SignedSource<<779349a199ec91c9f02b8ece87c803e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewAuditProgrammeQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuditProgrammeForm_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewAuditProgrammeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NormOrganisation",
        "kind": "LinkedField",
        "name": "normOrganisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "databaseId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ca3f16cffaa35a0b44bb5c316a76987",
    "id": null,
    "metadata": {},
    "name": "NewAuditProgrammeQuery",
    "operationKind": "query",
    "text": "query NewAuditProgrammeQuery {\n  ...AuditProgrammeForm_query\n}\n\nfragment AuditProgrammeForm_query on Query {\n  normOrganisations {\n    norm {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "9a025a6fbbb55c2691f65815e53ee421";

module.exports = node;
