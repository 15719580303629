/**
 * @generated SignedSource<<202faccd9c02fc61baa3b27faa45f245>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auditProgrammeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditFindingsClustersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditFindingsCluster",
            "kind": "LinkedField",
            "name": "auditFindingsClusters",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AuditFindingsCluster_auditFindingsCluster"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditProgramme",
            "kind": "LinkedField",
            "name": "auditProgramme",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "FindingNormParagraphs_audit"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewAuditFindingsClusterModal_audit"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditFindingsClustersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditFindingsCluster",
            "kind": "LinkedField",
            "name": "auditFindingsClusters",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auditNormParagraphsCount",
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditProgramme",
            "kind": "LinkedField",
            "name": "auditProgramme",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "auditId",
                    "variableName": "id"
                  }
                ],
                "concreteType": "AuditNormElement",
                "kind": "LinkedField",
                "name": "auditNormElements",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "seenAndApproved",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "auditFindingIds",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditFinding",
                    "kind": "LinkedField",
                    "name": "auditFindings",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditFindingsCluster",
                    "kind": "LinkedField",
                    "name": "auditFindingsCluster",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditInterview",
                    "kind": "LinkedField",
                    "name": "auditInterview",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobTitle",
                        "kind": "LinkedField",
                        "name": "jobTitle",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditFindingsCluster",
            "kind": "LinkedField",
            "name": "seenAndApprovedCluster",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e188ae3cbc57b73ab7b11aa8d9a9e47",
    "id": null,
    "metadata": {},
    "name": "AuditFindingsClustersQuery",
    "operationKind": "query",
    "text": "query AuditFindingsClustersQuery(\n  $id: ID!\n) {\n  audit(id: $id) {\n    auditProgrammeId\n    databaseId\n    name\n    auditFindingsClusters {\n      id\n      name\n      ...AuditFindingsCluster_auditFindingsCluster\n    }\n    auditProgramme {\n      databaseId\n      name\n      id\n    }\n    ...FindingNormParagraphs_audit_1Bmzm5\n    ...NewAuditFindingsClusterModal_audit\n    id\n  }\n}\n\nfragment AuditFindingsClusterModal_auditFindingsCluster on AuditFindingsCluster {\n  description\n  id\n  name\n  rating\n}\n\nfragment AuditFindingsCluster_auditFindingsCluster on AuditFindingsCluster {\n  auditNormParagraphsCount\n  name\n  rating\n  ...AuditFindingsClusterModal_auditFindingsCluster\n}\n\nfragment FindingNormParagraph_audit on Audit {\n  id\n  auditFindingsClusters {\n    databaseId\n    name\n    id\n  }\n  seenAndApprovedCluster {\n    databaseId\n    id\n  }\n  ...NewAuditFindingsClusterModal_audit\n}\n\nfragment FindingNormParagraph_normParagraph on NormParagraph {\n  id\n  name\n  number\n  auditNormElements(auditId: $id) {\n    databaseId\n    id\n    seenAndApproved\n    auditFindings {\n      databaseId\n      description\n      rating\n      id\n    }\n    auditFindingsCluster {\n      id\n      name\n      rating\n    }\n    auditInterview {\n      id\n      jobTitle {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment FindingNormParagraphs_audit_1Bmzm5 on Audit {\n  normParagraphs {\n    id\n    auditNormElements(auditId: $id) {\n      seenAndApproved\n      auditFindingIds\n      id\n    }\n    ...FindingNormParagraph_normParagraph\n  }\n  ...FindingNormParagraph_audit\n}\n\nfragment NewAuditFindingsClusterModal_audit on Audit {\n  databaseId\n  id\n}\n"
  }
};
})();

node.hash = "f29733c46518822fc8af8da838856279";

module.exports = node;
