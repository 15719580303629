import {
  faBold,
  faH1,
  faIndent,
  faItalic,
  faLink,
  faListDots,
  faListOl,
  faOutdent,
  faRedo,
  faStrikethrough,
  faUnderline,
  faUndo,
} from '@fortawesome/pro-regular-svg-icons';

export const AUDIT_INTERVIEW_DURATIONS = [
  {
    duration: '30',
    unit: 'min',
    value: 0.5,
  },
  {
    duration: '45',
    unit: 'min',
    value: 0.75,
  },
  {
    duration: '1',
    unit: 'hour',
    value: 1,
  },
  {
    duration: '1,5',
    unit: 'hour',
    value: 1.5,
  },
  {
    duration: '2',
    unit: 'hour',
    value: 2,
  },
  {
    duration: '2,5',
    unit: 'hour',
    value: 2.5,
  },
  {
    duration: '3',
    unit: 'hour',
    value: 3,
  },
];

export const AUDIT_RATINGS = ['major', 'minor', 'observation', 'opportunity_for_improvement', 'remarkable_effort'];

export const CLASSIFICATIONS = ['internal', 'public', 'confidential'];
export const DEVIATION_STATUSES = ['new', 'in_progress', 'waiting', 'rejected', 'overdue', 'done'];
export const PRIORITY = ['low', 'middle', 'high'];
export const DEVIATION_TYPES = [
  'data_leak',
  'vim',
  'complaint_application',
  'customer_evaluation',
  'customer_satisfaction_survey',
  'external_audit',
  'improvement_proposal',
  'incident',
  'internal_audit',
  'management_review',
  'manual',
  'null_meeting',
  'operation_message',
  'dpia',
  'privacy_information_request',
  'risk_analyse',
  'staff_satisfaction_surbey',
  'supper_evaluation',
];

export const COLORS = [
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff',
  '#000000',
];

const fileDate = new Date();
export const FILEDATEFORMAT = `${fileDate.getFullYear()}${(fileDate.getMonth() + 1)
  .toString()
  .padStart(2, '0')}${fileDate.getDate().toString().padStart(2, '0')}`;

export const FOCUS_AREAS = [
  'quality',
  'environment',
  'mvo',
  'information_security',
  'business_continuity',
  'working_conditions_act',
  'general',
  'privacy',
];

export const REPEATING_PERIODS = ['day', 'week', 'month', 'year'];

export const MEMBER_STATUSES = ['active', 'pending', 'inactive'];

export const MEMBER_ROLES = ['admin', 'editor', 'author', 'writer', 'subscriber', 'auditor'];

export const PR_ROLES = ['processor', 'responsible', 'shared', 'third_party'];

export const PR_COMPLIANT = ['true', 'false'];

export const PR_LAWFULNESS = [
  'consent',
  'contract',
  'legal_obligation',
  'vital_interests',
  'public_authority_interests',
  'legitimate_interests',
];

export const PR_GUARANTEES = ['model_contract', 'corporate_rules', 'adeqeacy_decision', 'other'];

export const RATINGS = [
  'major_nonconformity',
  'minor_nonconformity',
  'observation',
  'opportunity_for_improvement',
  'compliant_with_law_and_regulations',
  'remarkable_effort',
];

export const SUSPENSE_CONFIG = { timeoutMs: 2000 };

export const TASK_STATUSES = ['new', 'assigned', 'overdue', 'review', 'waiting', 'rejected', 'closed'];

export const LEVELS = ['low', 'middle', 'high'];

export const PERSONAL_DATA_CATEGORIES = ['regular', 'special', 'sensitive'];

export const TOOLBAR_ACTIONS = [
  'bold',
  'italic',
  'underline',
  'strike',
  'link',
  'heading1',
  'bullets',
  'numbers',
  'outdent',
  'indent',
  'undo',
  'redo',
];

export const TOOLBAR_ACTION_OPTS = {
  bold: {
    classNames: 'trix-button trix-button--icon trix-button--icon-bold',
    icon: faBold,
    languageKey: 'bold',
    trixButtonGroup: 'text-tools',
    data: {
      trixAttribute: 'bold',
      trixKey: 'b',
    },
  },
  italic: {
    classNames: 'trix-button trix-button--icon trix-button--icon-italic',
    icon: faItalic,
    languageKey: 'italic',
    trixButtonGroup: 'text-tools',
    data: {
      trixAttribute: 'italic',
      trixKey: 'i',
    },
  },
  underline: {
    classNames: 'trix-button trix-button--icon trix-button--icon-underline',
    icon: faUnderline,
    languageKey: 'underline',
    trixButtonGroup: 'text-tools',
    data: {
      trixAttribute: 'underline',
      trixKey: 'u',
    },
  },
  strike: {
    classNames: 'trix-button trix-button--icon trix-button--icon-strike',
    icon: faStrikethrough,
    languageKey: 'strike',
    trixButtonGroup: 'text-tools',
    data: {
      trixAttribute: 'strike',
    },
  },
  link: {
    classNames: 'trix-button trix-button--icon trix-button--icon-link',
    icon: faLink,
    languageKey: 'link',
    trixButtonGroup: 'text-tools',
    data: {
      trixAttribute: 'href',
      trixKey: 'k',
      trixAction: 'link',
    },
  },
  heading1: {
    classNames: 'trix-button trix-button--icon trix-button--icon-heading-1',
    icon: faH1,
    languageKey: 'heading1',
    trixButtonGroup: 'block-tools',
    data: {
      trixAttribute: 'heading1',
    },
  },
  bullets: {
    classNames: 'trix-button trix-button--icon trix-button--icon-bullet-list',
    icon: faListDots,
    languageKey: 'bullets',
    trixButtonGroup: 'block-tools',
    data: {
      trixAttribute: 'bullet',
    },
  },
  numbers: {
    classNames: 'trix-button trix-button--icon trix-button--icon-number-list',
    icon: faListOl,
    languageKey: 'numbers',
    trixButtonGroup: 'block-tools',
    data: {
      trixAttribute: 'number',
    },
  },
  outdent: {
    classNames: 'trix-button trix-button--icon trix-button--icon-decrease-nesting-level',
    icon: faOutdent,
    languageKey: 'outdent',
    trixButtonGroup: 'block-tools',
    data: {
      trixAction: 'decreaseNestingLevel',
    },
  },
  indent: {
    classNames: 'trix-button trix-button--icon trix-button--icon-increase-nesting-level',
    icon: faIndent,
    languageKey: 'indent',
    trixButtonGroup: 'block-tools',
    data: {
      trixAction: 'increaseNestingLevel',
    },
  },
  undo: {
    classNames: 'trix-button trix-button--icon trix-button--icon-undo',
    icon: faUndo,
    languageKey: 'undo',
    trixButtonGroup: 'history-tools',
    data: {
      trixAction: 'undo',
      trixKey: 'z',
    },
  },
  redo: {
    classNames: 'trix-button trix-button--icon trix-button--icon-redo',
    icon: faRedo,
    languageKey: 'redo',
    trixButtonGroup: 'history-tools',
    data: {
      trixAction: 'redo',
      trixKey: 'shift+z',
    },
  },
};

export const TOOLBAR_BUTTON_GROUPS = ['text-tools', 'block-tools', 'history-tools'];

export const RISKABLES = ['JobTitle', 'ManualParagraph', 'Supplier', 'Resource'];

export const RISK_STATUSES = ['new', 'in_progress', 'accepted', 'closed'];

export const RISK_TYPES = [
  { databaseId: 'availability' },
  { databaseId: 'confidentiality' },
  { databaseId: 'integrity' },
];

export const NORM_REQUIREMENT_TYPES = [
  { databaseId: 'information_source' },
  { databaseId: 'job_title' },
  { databaseId: 'manual_paragraph' },
  { databaseId: 'resource' },
  { databaseId: 'supplier' },
];

export const RTO_RPO_OPTIONS = [
  { hours: 2 },
  { hours: 4 },
  { hours: 8 },
  { hours: 24 },
  { hours: 48 },
  { hours: 72 },
  { days: 7 },
  { months: 1 },
];
