/**
 * @generated SignedSource<<444416c58d0489cb117f03d1b1d99e43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auditProgrammeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "days",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfDays",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userIds",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitleId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mandatory",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownerManualParagraphIds",
  "storageKey": null
},
v16 = [
  (v3/*: any*/),
  (v5/*: any*/)
],
v17 = [
  {
    "kind": "Literal",
    "name": "roles",
    "value": [
      "admin",
      "editor"
    ]
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "name"
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "asc"
  },
  {
    "kind": "Literal",
    "name": "statuses",
    "value": [
      "active",
      "pending"
    ]
  }
],
v18 = [
  (v3/*: any*/),
  (v5/*: any*/),
  (v15/*: any*/),
  (v7/*: any*/)
],
v19 = [
  (v3/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditAgendaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditInterview",
            "kind": "LinkedField",
            "name": "auditInterviews",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "jobTitle",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditProgramme",
            "kind": "LinkedField",
            "name": "auditProgramme",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v16/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": (v16/*: any*/),
        "storageKey": "users(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuditAgendaInterview_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditAgendaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditInterview",
            "kind": "LinkedField",
            "name": "auditInterviews",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "jobTitle",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditProgramme",
            "kind": "LinkedField",
            "name": "auditProgramme",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v19/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": (v19/*: any*/),
        "storageKey": "users(roles:[\"admin\",\"editor\"],sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "JobTitleConnection",
        "kind": "LinkedField",
        "name": "jobTitles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitle",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v18/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac56824c3c4781f8b359e4c1f73ee07d",
    "id": null,
    "metadata": {},
    "name": "AuditAgendaQuery",
    "operationKind": "query",
    "text": "query AuditAgendaQuery(\n  $id: ID!\n) {\n  audit(id: $id) {\n    auditProgrammeId\n    databaseId\n    days\n    name\n    numberOfDays\n    id\n    userIds\n    auditInterviews {\n      databaseId\n      day\n      endTime\n      id\n      jobTitleId\n      mandatory\n      startTime\n      userId\n      jobTitle {\n        databaseId\n        name\n        ownerManualParagraphIds\n        id\n      }\n    }\n    auditProgramme {\n      name\n      id\n    }\n    users {\n      databaseId\n      name\n      id\n    }\n  }\n  users(roles: [\"admin\", \"editor\"], sortBy: \"name\", sortDirection: \"asc\", statuses: [\"active\", \"pending\"]) {\n    databaseId\n    name\n    id\n  }\n  ...AuditAgendaInterview_query\n}\n\nfragment AuditAgendaInterview_query on Query {\n  jobTitles {\n    nodes {\n      databaseId\n      name\n      ownerManualParagraphIds\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "fc936e3f76d81c5673c9f1d5d0bc41d9";

module.exports = node;
